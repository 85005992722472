<template>
  <div style="height: 100%">
    <v-form ref="form" v-model="customerRankValid">
      <!-- Start: Top -->
      <div class="d-flex justify-space-between">
        <!-- Start: Name -->
        <v-hover v-slot:default="{ hover }">
          <v-text-field
            class="mr-5 text-h6 px-0"
            v-model="customerRank.name"
            :rules="[customerRankRules.nameRequired]"
            solo
            single-line
            dense
            :outlined="hover"
            flat
            placeholder="Nhập tên hạng khách hàng"
          ></v-text-field>
        </v-hover>
        <!-- End: Name -->
        <!-- Start: Buttons group -->
        <div>
          <v-btn
            v-if="customerRank.id"
            class="font-weight-bold mr-4"
            depressed
            color="grey darkend-1"
            rounded
            icon
            :disabled="
              customerRankStatusRequest === 'loading-delete-customerRank'
            "
            @click="deleteCustomerRank()"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="font-weight-bold grey--text text--darken-3 rounded-lg mr-4"
            color="grey lighten-3"
            depressed
            @click="resetCustomerRank()"
          >
            Hủy
          </v-btn>
          <v-btn
            class="font-weight-bold rounded-lg"
            depressed
            color="primary"
            :disabled="!customerRankValid"
            @click="
              customerRankStatusRequest === 'loading-update-customerRank' ||
              customerRankStatusRequest === 'loading-create-customerRank'
                ? null
                : customerRank.id
                ? updateCustomerRank()
                : createCustomerRank()
            "
          >
            {{ customerRank.id ? "Lưu" : "Thêm" }}
          </v-btn>
        </div>
        <!-- End: Buttons group -->
      </div>
      <!-- End: Top -->
      <!-- Start: Main -->
      <div class="mt-2">
        <!-- Start: Description -->
        <div>
          <div class="font-weight-bold mb-2">Mô tả</div>
          <v-textarea
            v-model="customerRank.description"
            class="text-body-1"
            outlined
            placeholder="Nhập mô tả"
            rows="2"
          ></v-textarea>
        </div>
        <!-- End: Description -->
        <!-- Start: Conditions -->
        <div class="mb-4">
          <div class="font-weight-bold mb-2">
            Tiêu chí khách hàng
          </div>
          <div>
            <tp-input-condition
              v-for="(item, index) in customerRank.dks"
              :key="index"
              :attributes="customerRankConditions"
              :value="item"
              @change:name="item.name = $event"
              @change:db="item.db = $event"
              @change:condition="item.dk = $event"
              @change:value="item.list = $event"
              @remove="removeCondition(index)"
            ></tp-input-condition>
          </div>
          <v-btn
            class="rounded-lg mt-2"
            color="grey--text text--darken-2 text-none"
            depressed
            @click="addCondition()"
          >
            Thêm tiêu chí
          </v-btn>
        </div>
        <!-- End: Conditions -->
      </div>
      <!-- End: Main -->
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    currentCustomerRankIndex: Number
  },
  data: () => ({
    customerRankRules: {
      nameRequired: value => !!value || "Bạn chưa nhập tên hạng khách hàng."
    },
    customerRankValid: true,
    selectedItems: []
  }),
  computed: {
    customerRank() {
      return this.$store.getters["CUSTOMER_RANK/customerRank"];
    },
    customerRankConditions() {
      return this.$store.getters["CUSTOMER_RANK/customerRankConditions"];
    },
    customerRanks() {
      return this.$store.getters["CUSTOMER_RANK/customerRanks"];
    },
    customerRankStatusRequest() {
      return this.$store.getters["CUSTOMER_RANK/statusRequest"];
    }
  },
  created() {
    this.$store.dispatch("CUSTOMER_RANK/getCustomerRankConditions");
  },
  methods: {
    addCondition() {
      this.customerRank.dks.push({
        name: null,
        db: null,
        dk: [],
        list: []
      });
    },

    async createCustomerRank() {
      await this.validateCustomerRank();

      if (this.customerRankValid) {
        await this.$store.dispatch(
          "CUSTOMER_RANK/createCustomerRank",
          this.customerRank
        );
        if (
          this.customerRankStatusRequest.value === "success-createCustomerRank"
        ) {
          this.$emit(
            "updateCurrentCustomerRankIndex",
            this.customerRanks.length - 1
          );
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Đã thêm hạng khách hàng"
            }
          });
        }
      }
    },

    async deleteCustomerRank() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa vai trò <strong>${this.customerRank.name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("CUSTOMER_RANK/deleteCustomerRank", {
                id: this.customerRank.id,
                index: this.currentCustomerRankIndex
              });
              if (
                this.customerRankStatusRequest.value ===
                "success-deleteCustomerRank"
              ) {
                this.$emit("updateCurrentCustomerRankIndex", null);
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa hạng khách hàng"
                  }
                });
              }
            }
          }
        }
      });
    },

    removeCondition(index) {
      this.customerRank.dks.splice(index, 1);
    },

    resetCustomerRank() {
      this.$emit("updateCurrentCustomerRankIndex", null);
      this.$store.dispatch("CUSTOMER_RANK/resetCustomerRank");
    },

    async updateCustomerRank() {
      await this.validateCustomerRank();

      if (this.customerRankValid) {
        await this.$store.dispatch("CUSTOMER_RANK/updateCustomerRank", {
          customerRank: this.customerRank,
          customerRankIndex: this.currentCustomerRankIndex
        });
        if (
          this.customerRankStatusRequest.value === "success-updateCustomerRank"
        ) {
          // Alert
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message: "Đã lưu thay đổi"
            }
          });
        }
      }
    },
    validateCustomerRank() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.treeview--scrollable {
  max-height: calc(100% - 113px);
  overflow-y: scroll;
}
</style>
