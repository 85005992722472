<template>
  <v-list class="py-0 grey lighten-3" style="height: 100%">
    <h3 class="font-weight-bold mb-4">
      Hạng khách hàng ({{ customerRanks.length }})
    </h3>
    <v-list-item
      class="rounded-lg white mb-3 customerRank-list--item"
      :class="customerRank.id ? 'customerRank-add--disable' : null"
      link
      @click="customerRank.id ? resetCustomerRank() : null"
    >
      <v-list-item-content>
        <v-icon>mdi-plus</v-icon>
      </v-list-item-content>
    </v-list-item>

    <v-list class="list--scrollable py-0 grey lighten-3">
      <v-list-item-group
        v-model="computedCurrentCustomerRankIndex"
        active-class="primary white--text font-weight-bold"
        color="primary"
      >
        <v-list-item
          class="customerRank-list--item rounded-lg white mb-3"
          v-for="(item, index) in customerRanks"
          :key="index"
          @click="selectCustomerRank(item, index)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-list>
</template>

<script>
export default {
  props: {
    currentCustomerRankIndex: Number
  },
  computed: {
    computedCurrentCustomerRankIndex: {
      get() {
        return this.currentCustomerRankIndex;
      },
      set(val) {
        this.$emit("updateCurrentCustomerRankIndex", val);
      }
    },
    customerRank() {
      return this.$store.getters["CUSTOMER_RANK/customerRank"];
    },
    customerRanks() {
      return this.$store.getters["CUSTOMER_RANK/customerRanks"];
    }
  },
  methods: {
    resetCustomerRank() {
      this.$emit("updateCurrentCustomerRankIndex", null);
      this.$store.dispatch("CUSTOMER_RANK/resetCustomerRank");
    },
    selectCustomerRank(customerRank, index) {
      if (index === this.currentCustomerRankIndex) return;

      this.$store.dispatch(
        "CUSTOMER_RANK/getCustomerRankById",
        customerRank.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.list--scrollable {
  max-height: calc(100% - 100px);
  overflow-y: scroll;
}
.customerRank-list--item {
  &::before {
    border-radius: 0.5rem;
  }
}
</style>
