var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('v-form',{ref:"form",model:{value:(_vm.customerRankValid),callback:function ($$v) {_vm.customerRankValid=$$v},expression:"customerRankValid"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-text-field',{staticClass:"mr-5 text-h6 px-0",attrs:{"rules":[_vm.customerRankRules.nameRequired],"solo":"","single-line":"","dense":"","outlined":hover,"flat":"","placeholder":"Nhập tên hạng khách hàng"},model:{value:(_vm.customerRank.name),callback:function ($$v) {_vm.$set(_vm.customerRank, "name", $$v)},expression:"customerRank.name"}})]}}])}),_c('div',[(_vm.customerRank.id)?_c('v-btn',{staticClass:"font-weight-bold mr-4",attrs:{"depressed":"","color":"grey darkend-1","rounded":"","icon":"","disabled":_vm.customerRankStatusRequest === 'loading-delete-customerRank'},on:{"click":function($event){return _vm.deleteCustomerRank()}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{staticClass:"font-weight-bold grey--text text--darken-3 rounded-lg mr-4",attrs:{"color":"grey lighten-3","depressed":""},on:{"click":function($event){return _vm.resetCustomerRank()}}},[_vm._v(" Hủy ")]),_c('v-btn',{staticClass:"font-weight-bold rounded-lg",attrs:{"depressed":"","color":"primary","disabled":!_vm.customerRankValid},on:{"click":function($event){_vm.customerRankStatusRequest === 'loading-update-customerRank' ||
            _vm.customerRankStatusRequest === 'loading-create-customerRank'
              ? null
              : _vm.customerRank.id
              ? _vm.updateCustomerRank()
              : _vm.createCustomerRank()}}},[_vm._v(" "+_vm._s(_vm.customerRank.id ? "Lưu" : "Thêm")+" ")])],1)],1),_c('div',{staticClass:"mt-2"},[_c('div',[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Mô tả")]),_c('v-textarea',{staticClass:"text-body-1",attrs:{"outlined":"","placeholder":"Nhập mô tả","rows":"2"},model:{value:(_vm.customerRank.description),callback:function ($$v) {_vm.$set(_vm.customerRank, "description", $$v)},expression:"customerRank.description"}})],1),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v(" Tiêu chí khách hàng ")]),_c('div',_vm._l((_vm.customerRank.dks),function(item,index){return _c('tp-input-condition',{key:index,attrs:{"attributes":_vm.customerRankConditions,"value":item},on:{"change:name":function($event){item.name = $event},"change:db":function($event){item.db = $event},"change:condition":function($event){item.dk = $event},"change:value":function($event){item.list = $event},"remove":function($event){return _vm.removeCondition(index)}}})}),1),_c('v-btn',{staticClass:"rounded-lg mt-2",attrs:{"color":"grey--text text--darken-2 text-none","depressed":""},on:{"click":function($event){return _vm.addCondition()}}},[_vm._v(" Thêm tiêu chí ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }