<template>
  <v-row class="pr-5 mx-0">
    <v-col class="height--fixed px-5 py-0" cols="3">
      <list-customer-ranks
        :current-customer-rank-index="currentCustomerRankIndex"
        @updateCurrentCustomerRankIndex="currentCustomerRankIndex = $event"
      ></list-customer-ranks>
    </v-col>
    <v-col class="height--fixed white py-4 px-5 rounded" cols="9">
      <customer-rank-detail
        :current-customer-rank-index="currentCustomerRankIndex"
        @updateCurrentCustomerRankIndex="currentCustomerRankIndex = $event"
      ></customer-rank-detail>
    </v-col>
  </v-row>
</template>

<script>
import CustomerRankDetail from "./components/CustomerRankDetail";
import ListCustomerRanks from "./components/ListCustomerRanks";

export default {
  components: {
    CustomerRankDetail,
    ListCustomerRanks
  },
  data() {
    return {
      currentCustomerRankIndex: null
    };
  },
  created() {
    this.$store.dispatch("CUSTOMER_RANK/getCustomerRanks");
  }
};
</script>

<style lang="scss" scoped>
.height--fixed {
  max-height: calc(100vh - 64px - 53px - 24px);
  overflow: hidden;
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px);
  }
}
</style>
